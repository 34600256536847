<template>
  <app-dropdown-filter
    v-model="model"
    icon="ph:timer"
    item-text="label"
    item-value="value"
    :items="items"
    :label="$t('course.format.filter.label')"
    :placeholder="$t('course.format.filter.placeholder')"
    :value-label="valueLabel"
  />
</template>

<script lang="ts" setup>
import type { I18nMessage } from "@pollen/core/model/i18n.model";

const properties = defineProps<{
  modelValue?: string;
}>();

const model = useVModel(properties, "modelValue");

const { t } = useI18n();
const items: { label: I18nMessage; value: string | undefined }[] = [
  { label: t("course.format.label.full_day"), value: "P1D" },
  { label: t("course.format.label.half_day"), value: "P0.5D" },
];

const valueLabel = computed(
  () => items.find(({ value }) => model.value === value)?.label,
);
</script>
